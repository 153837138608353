<template>
  <div class="page" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- <div class="page-title">
      <span class="icon-wrap">
        <eva-icon
          name="folder-remove-outline"
          width="20"
          height="20"
          fill="#FFF"
        ></eva-icon>
      </span>
      專案管理
    </div> -->
    <div class="page-map">庫存資料查詢</div>
    <div class="page-header">
      <div class="filter-card">
        <!-- <div class="filter-field">
          <label>日期區間(起)</label>
          <date-picker
            format="YYYY-MM-DD"
            valueType="format"
            placeholder="YYYY-MM-DD"
            v-model="project.BDate"
          ></date-picker>
        </div>
        <div class="filter-field">
          <label>日期區間(訖)</label>
          <date-picker
            format="YYYY-MM-DD"
            valueType="format"
            placeholder="YYYY-MM-DD"
            v-model="project.EDate"
          ></date-picker>
        </div>
        <div class="filter-field">
          <label>模糊查詢</label>
          <input
            v-model="project.Condition"
            type="text"
            value=""
            placeholder="請輸入關鍵字"
          />
        </div>

        <div class="filter-field">
          <label>狀態</label>
          <select v-model="project.Project_Type">
            <option
              v-for="option in statusList"
              :key="option.StatusCode"
              :value="option.StatusCode"
            >
              {{ option.StatusName }}
            </option>
          </select>
        </div> -->

        <!-- <a class="btn btn-primary" href="#" @click.prevent="getProjectList"
          >查詢</a> -->
        <button class="btn btn-sm btn-primary mr-2">QC Hold/Release</button>
        <button class="btn btn-sm btn-info" data-toggle="modal" data-target="#uploadModal">匯入資料</button>
        
      </div>
    </div>
    <div class="page-body">
      <div class="text-right mb-3">
        <!-- <router-link
          :to="{ path: 'projectCreate' }"
          class="btn btn-primary btn-sm"
        >
          新增
        </router-link> -->

        <!-- <a class="btn btn-primary btn-sm" href="#" @click="openCreateModal"
          >新增</a
        > -->
      </div>
      <div class="table-wrap">
        <el-table :data="rows" style="width: 100%" class="detail--table" :default-sort = "{prop: 'Billing_Date', order: 'descending'}">
          <el-table-column label="料號" prop="PartNo">
          </el-table-column>
          <!-- <el-table-column label="部門" prop="Department"> </el-table-column> -->
          <el-table-column label="Wafer Lot" prop="WaferLot"> </el-table-column>
          <el-table-column label="Substrate ID" prop="SubstrateID">
          </el-table-column>
          <el-table-column label="倉別" prop="Comp">
            <!-- <template slot-scope="props">
              {{ props.row.Billing_Date | moment }}
            </template> -->
          </el-table-column>

          <el-table-column label="批號" prop="Batch">
          </el-table-column>
          
          <el-table-column label="膠別" prop="Glue">
          </el-table-column>

          <el-table-column label="厚度(um)" prop="Thickness">
          </el-table-column>

          <el-table-column label="異動單號" prop="ChangeNum">
          </el-table-column>

          <el-table-column label="庫存數量" prop="InventoryQuantity">
          </el-table-column>

          <el-table-column label="使用數量" prop="UsageAmount">
          </el-table-column>

          <el-table-column label="剩餘數量" prop="RemainingAmount">
          </el-table-column>

          <el-table-column label="備註" prop="Memo">
          </el-table-column>

          <el-table-column label="Hold/Release" prop="Status">
          </el-table-column>

          <el-table-column label="最後異動日期" prop="FinalChangeDate">
          </el-table-column>

          <el-table-column label="最後異動時間" prop="FinalChangeTime">
          </el-table-column>

          
          <el-table-column label="操作" fixed="right">
            <template>
              <!-- <router-link
              :to="{ path: 'ProjectMaintain' }"
              class="btn btn-primary btn-sm"
            >
              維護
            </router-link> -->
              <!-- <button
                class="btn btn-primary btn-sm"
                @click="editProject(props.row)"
              >
                維護
              </button> -->
              <router-link :to="{ path: '/manager/bookingData' }" class="btn btn-primary btn-sm">
                詳細資料
              </router-link>
              <!-- <button
                type="button"
                class="btn btn-danger btn-sm"
                @click.prevent="openDeleteModal"
              >
                終止
              </button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>



    <!-- 匯入資料 Modal-->
    <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>匯入資料</span>
          </div>
          <div class="modal-body" style="margin-bottom: 20px">
            <p class="form-group">
              <u
                style="color: midnightblue; cursor: pointer;"
                >點擊下載範例檔</u
              >
            </p>
            <p class="form-group">
              <span class="mark">* 檔案限制：[檔名].xlsx、[檔名].xls</span
              ><br />
              <span class="mark">* 大小限制：10MB 以下</span><br />
              <span class="mark">* 上傳前請確認是否符合格式</span><br />
            </p>
            <div class="from-group">
              <label><span class="mark">*</span>檔案</label>
              <el-upload
                ref="upload"
                action
                accept=".xls,.xlsx"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="uploadFile"
                :limit="1"
              >
                <el-button size="small" type="primary">點擊上傳</el-button>
              </el-upload>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              <eva-icon name="close-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              <eva-icon name="checkmark-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
    data() {
        return {
            rows: [
                {
                    PartNo: "123134",
                    WaferLot: "45687",
                    SubstrateID: "a845ew5q-sa4",
                    Comp: "台北",
                    Batch: "84645",
                    Glue: "SOA",
                    Thickness: 25,
                    ChangeNum: "154845145",
                    InventoryQuantity: 2500,
                    UsageAmount: 1000,
                    RemainingAmount: 480,
                    Memo: 'test',
                    Status: "Hold",
                    FinalChangeDate: "2021/10/19",
                    FinalChangeTime: "15:24",
                }
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
.table-select {
  padding: 0 10px;
  border: none;
  background-color: transparent;
  // border-bottom:1px solid rgb(190, 190, 190) ;
  height: 30px;
  outline: none;
  cursor: pointer;
}

.mark {
    background-color: inherit;
    color: red;
}
</style>
